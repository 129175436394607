import React from "react";
import { graphql } from "gatsby";
import theme from "gatsby-theme-googlesheets-personal-web/src/theme";
import Layout from "gatsby-theme-googlesheets-personal-web/src/components/Layout";
import Flex from "gatsby-theme-googlesheets-personal-web/src/components/Flex";
import Articles from "components/publications/Articles";
import Thesis from "components/publications/Thesis";
import Teaching from "components/publications/Teaching";

export default ({
  data: {
    articles: { totalCount },
  },
}) => (
  <Layout>
    <Flex direction="column" grow={1} halign="stretch">
      <h1>My publications</h1>

      <h2 style={theme.markdown.h2}>PhD thesis</h2>
      <Thesis />

      <h2 style={theme.markdown.h2}>
        Research articles &nbsp;
        <span
          style={{
            color: theme.colors.grey2,
            fontWeight: 400,
          }}
        >
          ({totalCount})
        </span>
      </h2>
      <Articles />

      <h2 style={theme.markdown.h2}>Some teaching material</h2>
      <Teaching />
    </Flex>
  </Layout>
);

export const query = graphql`
  {
    articles: allGoogleSpreadsheetArticles {
      totalCount
    }
  }
`;
