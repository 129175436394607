import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ArticleContainer, Article, Title, Author, Institution } from "./Bib";

const Thesis = ({
  data: {
    allGoogleSpreadsheetThesis: { theses },
  },
}) =>
  theses.map((thesis) => {
    let { id, author, title, date, institution, url } = thesis || {};
    // This is an ugly hack after moving to Hetzner and
    // starting to use TLS; refactor this.
    const prefix = "http://marjan.fesb.hr/~mcagalj/_publications";
    if (url.startsWith(prefix)) {
      url = url.replace(prefix, "");
    }
    return (
      <Article key={id}>
        <Title href={url}>{title}</Title>
        <Author>{author}</Author>
        <Institution>
          {institution}, {date}
        </Institution>
      </Article>
    );
  });

export default () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetThesis {
        theses: nodes {
          id
          author
          date
          institution
          title
          url
        }
      }
    }
  `);

  return (
    <ArticleContainer>
      <Thesis data={data} />
    </ArticleContainer>
  );
};
