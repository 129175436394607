import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import If from "utils/If";
import { ArticleContainer, Article, Title, Author, Institution } from "./Bib";

const Articles = ({
  data: {
    allGoogleSpreadsheetTeaching: { teaching },
  },
}) =>
  teaching.map((article) => {
    const { id, author, title, date, institution, url } = article || {};

    return (
      <Article key={id}>
        <Title href={url}>{title}</Title>
        <Author>{author}</Author>
        <If
          condition={institution}
          then={
            <Institution>
              {institution}, {date}
            </Institution>
          }
        />
      </Article>
    );
  });

export default () => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetTeaching {
        teaching: nodes {
          id
          author
          date
          institution
          title
          url
        }
      }
    }
  `);

  return (
    <ArticleContainer>
      <Articles data={data} />
    </ArticleContainer>
  );
};
